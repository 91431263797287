/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Section } from "@jfrk/react-heading-levels";
import { PageGridLayout, RuledList } from "@whitespace/components";
import usePages from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/pages";
import { getChildren } from "@whitespace/gatsby-theme-wordpress-basic/src/utils/pageTree";
import React from "react";

import BoxNavigationWithImage from "../components/BoxNavigation/BoxNavigationWithImage";
import LandingPageHero from "../components/LandingPageHero";
import SEO from "../components/seo";
import { sortItemsByTitle } from "../utils/sort";

export default function LandingPageTemplate({ pageContext }) {
  const {
    contentNode: { id: pageId, uri, title, serviceLink },
    isPreview,
  } = pageContext;

  const allPages = usePages();

  const childPages = getChildren(allPages, pageId)
    .filter((page) => page.showInMenu)
    .map((page) => ({
      ...page,
      preamble: page.description,
      items: getChildren(allPages, page.id)
        .filter((page) => page.showInMenu)
        .map((page) => ({
          ...page,
        })),
    }));

  const shortcuts = {};

  return (
    <>
      <SEO title={title} />
      <LandingPageHero
        title={title}
        shortcuts={
          shortcuts && shortcuts.length > 0 && sortItemsByTitle(shortcuts)
        }
        serviceLink={{
          url: serviceLink.url,
          title: `E-tjänster för ${title}`,
        }}
        className="c-hero-butler--left-aligned c-hero-butler--bottom-aligned"
      />
      <Section>
        <PageGridLayout className="o-margin-top-large o-margin-bottom-large">
          <RuledList
            css={css`
              margin-top: 2rem;
              --list-gap: 3rem;
            `}
          >
            {childPages.map(
              ({ title, uri, preamble, items, featuredImage }, index) => {
                return (
                  <BoxNavigationWithImage
                    key={index}
                    title={title}
                    image={featuredImage?.node}
                    link={uri}
                    preamble={preamble}
                    items={items}
                  />
                );
              },
            )}
          </RuledList>
        </PageGridLayout>
      </Section>
    </>
  );
}
